/**
 *  @description
 *  All action types related to Pairing the Device.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

export const START_REQUEST_PAIRING_CODE = 'myhealthcare:start_request_pairing_code';
export const RECEIVE_PAIRING_CODE = 'myhealthcare:receive_pairing_code';
export const ERROR_RECEIVING_PAIRING_CODE = 'myhealthcare:error_receiving_pairing_code';