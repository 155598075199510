/**
 *  @description
 *  Appointment form component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import { APP_SUB_FOLDER } from '../../../config/app-constants';

class AppointmentFormComponent extends Component {

    mainBox;
    constructor(props) {
        super(props);
        this.state = {
            first_open: 0,
            mobile_number: '',
            booking_code: '',
            mainBoxHeight: 0,
            errors: '',
            tokenRequested: false,
            loadingState:false,
            booking_code_errors:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.getToken = this.getToken.bind(this);
        this.getTokenForBooking = this.getTokenForBooking.bind(this);
        this.getWalkinToken = this.getWalkinToken.bind(this);
        this.formatTime = this.formatTime.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let height = this.mainBox ? this.mainBox.clientHeight : 0;
        if (prevState.mainBoxHeight !== height) {
            this.setState({
                mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
            });
        }
    }

    /** Update the state on input changes. */
    handleChange(e) {
        let target = e.target;
        const value = target.value;
        const name = target.name;
        if (name === 'mobile_number') {
            target.value = target.value.replace(/[^\d]/, '')
        }
        this.setState({
            [name]: value,
        });
    }

    getTokenForBooking(e, data) {
        if (!this.state.tokenRequested) {
            this.tokenRequested = true;
            var element = e.currentTarget;
            element.classList.add('clicked');
            var that = this;
            setTimeout(function () {
                element.classList.remove('clicked');
                that.props.getTokenForBooking(e, data);
            }, 500);
        }
    }


    getWalkinToken(e){
        this.props.getWalkinToken(e, {'mobile_number': this.state.mobile_number}, 1, 'false')
    }

    /** get Token for mobile number/booking code. responsible for validation. */
   async getToken(e) {
        e.preventDefault();
        this.setState({
            first_open: 0,
        });
        if (!this.state.booking_code) {
            if (this.state.mobile_number.length === 0) {
                this.setState({
                    errors: 'Mobile number is required.',
                });
            }
            else if (this.state.mobile_number.length !== 10) {
                this.setState({
                    errors: 'Mobile number should be 10 digit.',
                });
            }
            else {
                this.setState({
                    errors: '',
                    loadingState:true
                });
                await this.props.getTokenForBooking(e, this.state);
                this.setState({
                    first_open: 1,
                });

                
            }
        } else {
            
            if (this.state.booking_code) {
                let passw = /^([a-zA-Z0-9_-]){9,13}$/;
                ///^[A-Za-z0-9]*$/;
                if(!this.state.booking_code.match(passw)){ 
                    this.setState({
                        booking_code_errors: 'Booking code should be alphanumeric and 6-13 letters only',
                    });
                   return false;
                }else{
                    this.setState({
                        loadingState:true,
                        booking_code_errors: '',
                    });
                    await this.props.getTokenForBooking(e, this.state);
                    this.setState({
                        first_open: 1,
                    });
                }
            }
        }
    }

    formatTime(time) {
        if (!time) {
            return '';
        }
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1, 4);  // Remove full string match value
            time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    render() {

        
        let bookings = this.props.token && this.props.token.data && this.props.token.data.data.bookings;
        let error = this.props.token && this.props.token.error && this.props.token.error.response;
        if (bookings && this.state.first_open == 1) {
            const bookingList = bookings.map((booking) => (
                <div className="card-body">
                    <Row>
                        <Col xs="12" md="5">
                            <div>
                                <strong>{booking.patient.name}</strong>
                            </div>
                            <div>
                                <span>Booking Code</span> <strong className="time">{booking.booking.code}</strong>
                            </div>
                        </Col>
                        <Col xs="12" md="4" className="mt-3 mt-md-0">
                            <div>
                                <span>Doctor</span> <strong>{booking.doctor.name}</strong>
                            </div>
                            <div>
                                <span>Time</span> <strong className="time">{this.formatTime(booking.booking.start_time)}</strong>
                            </div>
                        </Col>
                        <Col xs="12" md="3" className="align-self-center text-center text-md-right mt-3 mt-md-0 get_token_btn">
                            <button type="submit" class="px-3 btn btn-primary primary" onClick={(e) => this.getTokenForBooking(e, { booking_code: booking.booking.code })} key={booking.booking.id}>Get Token</button>
                        </Col>
                    </Row>
                </div>
            ));
            return (
                <>
                    <div className='modal-header'>
                        <h5>{this.props.service.title}</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                    </div>
                    <div className="card appoinment_card">
                        {bookingList}
                        {/* <Row>
                            <Col xs="12"><div className='orwrap'>OR</div></Col>
                            <Col xs="12" className="text-center get_token_btn">
                                <strong style={{color:'#374380'}}>Generate additional Walk-In token</strong>
                                <button onClick={(e) => this.getWalkinToken(e)} type="submit" class="px-3 btn btn-primary primary ml-3">Get Token</button>
                            </Col>
                        </Row> */}
                    </div>
                </>
            )


        } else {
            return (
                
                <>
                    {/* <Row>
                        <Col xs={{ size: 4, offset: 4 }} className="text-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo-white.png"} alt="Logo" className="img-fluid" />
                        </Col>
                    </Row> */}
                    <div className='modal-header'>
                        <h5>{this.props.service.title}</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                    </div>            
                    <Form className="main-box" autocomplete="off" onSubmit={(e) => this.getToken(e)} ref={(mainBox) => this.mainBox = mainBox}>
                        <Row>
                            <Col xs="12" sm="5">
                                    <div><Input type="tel" name="mobile_number" placeholder="Mobile number" maxLength="10" autocomplete="off" onChange={this.handleChange} /></div>
                                    <h6>Preferably use mobile number already registered with SAKRA</h6>
                                    <div className="text-left"><small className="text-danger">{this.state.errors}</small></div>
                            </Col>
                            <Col xs="12" sm="2" ><div className="or">OR</div></Col>
                            <Col xs="12" sm="5">
                                    <div><Input type="text" name="booking_code" placeholder="Booking code" autocomplete="off" onChange={this.handleChange} maxLength={13}  /></div>
                                    <div className="text-left"><small className="text-danger">{error ? error.message : ''}{this.state.booking_code_errors}</small></div>
                            </Col>
                        </Row>
                        
                        <FormGroup className="text-center py-4">
                            {this.state.loadingState == false? <Button  type="submit" color="primary" className="px-5">Continue</Button>: <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/loading.gif"} alt=""  />}
                        </FormGroup>
                    </Form>
                </>
            )
        }
    }
}

export default AppointmentFormComponent;