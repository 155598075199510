/**
 *  @description
 *  All action types related to Tokens.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

export const START_REQUEST_GET_TOKEN = "myhealthcare:start_request_get_token";
export const RECEIVE_TOKEN = "myhealthcare:receive_token";
export const ERROR_RECEIVING_TOKEN = "myhealthcare:error_receiving_token";