/**
 *  @description
 *  Token reducer.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import {
    initialState
} from '../reducers/root-reducer';
import * as actionTypes from '../types/token-action-types';

export const TokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_REQUEST_GET_TOKEN:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.RECEIVE_TOKEN:
            return {
                ...state,
                data: action.payload,
                error: null,
                loading: false,
            };
        case actionTypes.ERROR_RECEIVING_TOKEN:
            return {
                ...state,
                data: null,
                error: action.payload,
            };
        default:
            return state;
    }
}