/**
 *  @description
 *  Loading Component
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';

class LoadingComponent extends Component {
    render() {
        return (
            <div className="loading">
                <div className="lds-dual-ring"></div>
                {/* <h4 className='font-weight-light'>Please wait while the device is being paired.</h4> */}
            </div>
        )
    }
}

export default LoadingComponent;