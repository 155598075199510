/**
 *  @description
 *  All constants for the App.
 *  @author
 *  Sanjay Kumar<sanjay&#64;myhealthcare.life>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

//export const API_URI = 'https://dev.appapis.desktop.myhealthcare.co/api/qms/android/v1';

export const API_URI = 'https://prerelease.mhappapis.vc.myhealthcare.co/api/qms/android/v1';
export const PORTAL_URI = 'https://prerelease.mh.vc.myhealthcare.co';

// export const API_URI = 'https://qms.s.myhealthcare.co/api/qms/android/v1'; // 'current'
// export const API_URI = 'https://mhappapis.vc.myhealthcare.co/api/qms/android/v1'; // 'old'
// export const PORTAL_URI = 'https://mh.vc.myhealthcare.co';


// export const API_URI = 'http://3.110.112.150/api/qms/android/v1';
// export const PORTAL_URI = 'http://15.207.236.90';


export const APP_SUB_FOLDER = "";
// export const APP_SUB_FOLDER = "/sakraworld"; // set PUBLIC_URL=/sakraworld
// export const APP_SUB_FOLDER = "/sakraclinic"; // set PUBLIC_URL=/sakraclinic
export const sakraclinic = true;

export const WEBSOCKET_URI = 'ws://192.168.1.154:8000';
export const POLLING_DELAY = 60000;
export const TOKEN_DIALOG_TIMEOUT = 12000;