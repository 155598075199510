/**
 *  @description
 *  Header Component
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
import {
    Navbar,
    NavbarBrand,
} from 'reactstrap';
import { APP_SUB_FOLDER, sakraclinic } from '../../../config/app-constants';

class HeaserComponent extends Component {
    render() {
        // let branch_name='';
        // if(this.props.branch && this.props.branch.data && this.props.branch.data.data) {
        //     branch_name = this.props.branch.data.data.branch.hospital_name;
        // }
        return(
            <header className="kiosk-header">
                <Navbar className="kiosk-navbar">
                    <NavbarBrand href={`${APP_SUB_FOLDER}/`}>
                        {sakraclinic == true?
                        <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/clinic-logo.svg"} alt="Logo" className="img-fluid clinic" />
                        :
                        <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/logo.svg"} alt="Logo" className="img-fluid" />
                        }
                    </NavbarBrand>
                    <div className="collect_token">Collect Token</div>
                    {/* <label className="mr-auto ml-3 mb-0">
                    {
                        branch_name.length > 0 ? <span className="kiosk-branch">{branch_name}</span> : ''
                    }
                    </label>
                    <img className="powered-by-icon ml-auto img-fluid" src={process.env.PUBLIC_URL + "/assets/images/myhealthcare-logo.png"} alt="myhealthcare" /> */}
                </Navbar>
            </header>
        )
    }
}
export default HeaserComponent;