/**
 *  @description
 *  Root reducer for Fortis MyHealthcare kiosk App.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (14-Dec-2018)
 */

import {
    combineReducers
} from 'redux';
import {
    PairingReducer
} from './pairing-reducer';
// import {
//     WebsocketReducer
// } from './websocket-reducer';
import {
    ServiceReducer
} from './service-reducer';
import {
    TokenReducer
} from './token-reducer';

export const initialState = {
    pair: null,
    services: [],
    //websocket: null,
    token: null,
};

export const rootReducer = combineReducers({
    pair: PairingReducer,
    //websocket: WebsocketReducer,
    services: ServiceReducer,
    token: TokenReducer,
});