/**
 *  @description
 *  Actions related to pairing the code.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import * as actionTypes from '../types/pairing-action-types';
import {
    API_URI
} from '../../config/app-constants';
import api from '../../config/api';

export const startRequest = () => ({
    type: actionTypes.START_REQUEST_PAIRING_CODE,
});

export const receiveData = (data) => ({
    type: actionTypes.RECEIVE_PAIRING_CODE,
    payload: data,
});

export const receiveError = (error) => ({
    type: actionTypes.ERROR_RECEIVING_PAIRING_CODE,
    payload: error,
});

export function getPairingCode(device_details) {
    return (dispatch) => {
        dispatch(startRequest());
        return api.post(API_URI + '/kiosk', {
                device_id: device_details.device_id
            })
            .then(
                response => dispatch(receiveData(response)),
                error => {
                    console.log(error);
                    dispatch(receiveError(error));
                }
            );
    }
}

/** Reset the pairing code. */
export function resetPairing() {
    return (dispatch) => {
        dispatch(receiveData({
            data: {
                status: false
            }
        }));
    }
}