/**
 *  @description
 *  Service component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

 import React, {
    Component
} from 'react';
import {
    Card,
    CardBody,
    // Tooltip
} from 'reactstrap';
import {
    PORTAL_URI
} from '../../../config/app-constants';
import { Interweave   } from 'interweave';

class ServiceComponent extends Component {

    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
        //   tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
        //   tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        if(this.props.service) {
            const service = this.props.service;
            let serviceTitle = service.title; 
            serviceTitle = serviceTitle.replace("OR", "<span>OR</span>");
            return (
                <Card className="service-card h-100 bg-gray new_token_card" onClick={() => { this.props.onClicked(service) }}>
                    <CardBody>
                        {/* <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={'service_' + service.id} toggle={this.toggle}>
                            {service.description}
                        </Tooltip>
                        <span className="info-icon" id={'service_' + service.id} onClick={ (e) => {e.stopPropagation();} }>
                            <img src={process.env.PUBLIC_URL + '/assets/images/ic-information.png'} alt="info" />
                        </span> */}
                        
                            <div className="card-text get_token">
                                <figure className="icon_wrap"><img src={ PORTAL_URI + "/uploads/" + service.image} alt="" className="card-icon" /></figure>
                                
                                <Interweave className="mb-0" tagName="h5" content={serviceTitle} />
                                {/* <h6>Please click here for token  {service.description} </h6> */}
                                <h6>Tap Here</h6>
                                
                            </div>
                    </CardBody>
                </Card>
            )
        } else {
            return (<div></div>)
        }
    }
}

export default ServiceComponent;