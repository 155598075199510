
/**
 *  @description
 *  Token component.
 *  @author
 *  Mahesh Hegde<mahesh@innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { 
    Component 
} from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

class TokenComponent extends Component {

    mainBox;

    constructor(props) {
        super(props);
        this.state = {
            mainBoxHeight: 0,
        }
        this.printToken = this.printToken.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getWalkinToken = this.getWalkinToken.bind(this);
    }

    componentDidMount() {
        this.setState({
            mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let height = this.mainBox ? this.mainBox.clientHeight : 0;
        if(prevState.mainBoxHeight !== height) {
            this.setState({
                mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
            });
        }
    }

    printToken() {
        var prtContent = document.getElementById("main_box");
        var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }

    formatDate(dateStr) {
        if(!dateStr){
            return ''
        }
        var [date, time] = dateStr.split(' ');
        var [year, month, day] = date.split('-');
        var [hours, minute] = time.split(':');
        date = new Date(year, month-1, day);
        var ampm = hours < 12 ? 'AM': 'PM';
        hours = hours % 12 || 12;
        time = hours +":" + minute + " "+ ampm;
        const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        date = date.toLocaleDateString('en-US', DATE_OPTIONS);
        return date+ ' | '+ time;
    }

    getWalkinToken(e, token){
        if(this.props.service && this.props.service.title === 'Senior Citizen'){
            this.props.getWalkinToken(e, {'mobile_number': this.props.mobileNumber}, 6, 'true', token)
        }else{
            this.props.getWalkinToken(e, {'mobile_number': this.props.mobileNumber}, 1, 'false', token)
        }
        
    }

    render() {
        if(this.props.token && this.props.token.data) {
            const token = this.props.token.data.data;
            return (
                <>

                    <div className='modal-header'>
                        <h5>Your Token Number Is</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                    </div>

                    <div className="token_display_wrap">
                        {token.prefix} {token.count < 1000 ? ("00" + token.count).slice(-3) : token.count }
                        {this.props.service.title === 'Appointment' ?
                        <>
                            <div className='token_type_apointment'>
                                <span>{this.props.service.title}</span>
                                {token.timestamp}
                                <strong>{this.formatDate(token.timestamp)}</strong>
                            </div>                            
                        </>
                        : ""
                        }
                        
                    </div>
                    <div className='timestamp'>{this.formatDate(token.timestamp)}</div>
                    
                    {token.cancel_message? 
                        <>
                            <div className='cancel_message'>{token.cancel_message}</div>
                        </>
                        : null
                    }
                    

                    {/* {
                       
                        (!this.props.token.data.data.appointment || (this.props.PatientsDataList && this.props.PatientsDataList.patients.length == 1))?  */}
                        <>
                            {/* <Row className="mb-3" >
                                <Col xs="12" className="text-center get_token_btn">
                                    <strong style={{color:'#374380'}}>Generate additional Walk-In token </strong>
                                    <button onClick={(e) => this.getWalkinToken(e, token.prefix)} type="submit" class="px-3 btn btn-primary primary ml-3">Get Token</button></Col>
                            </Row> */}
                        </>
                        {/* : ''
                    } */}
                   

                    {/* <Row>
                        <Col xs="4" className="p-0 text-center">
                            <small>Estimated waiting time</small>
                            <div>
                                <span className="h4">{token.average_time}</span>
                                <span> {token.time_measure}.</span>
                            </div>
                        </Col>
                        <Col xs="4" className="text-center">
                            <img src={process.env.PUBLIC_URL + "/assets/images/logo-white.png"} alt="Logo" className="img-fluid" />
                        </Col>
                        <Col xs="4" className="text-center">
                            <small>People waiting</small>
                            <div>
                                <span className="h4">{token.waiting_count}</span>
                            </div>
                        </Col>
                    </Row> */}


                    {/* <div className="p-relative p-4">
                        <div className="main-box" id="main_box" ref={ (mainBox) => this.mainBox = mainBox}>
                            <label className="token text-primary">{token.prefix}-{token.count < 1000 ? ("00" + token.count).slice(-3) : token.count }</label>
                            <p className="text-muted mb-2">{this.props.service.title}</p>
                            <p className="text-black mb-0">{this.formatDate(token.timestamp)}</p>
                        </div>
                        <div className="modal-bottom-panel" style={{paddingTop: (this.state.mainBoxHeight/2)}}>
                            <h6 className="text-center text-muted">Get ticket by:</h6>
                            <Row>
                                <Col xs={{size: 8, offset: 2}}>
                                    <Row className="justify-content-center">
                                        { 
                                            token.settings.send_sms ? 
                                                <Col xs="4">
                                                    <figure className="text-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/sms.png"} alt="SMS" width="30" />
                                                        <figcaption>SMS</figcaption>
                                                    </figure>
                                                </Col> : ""
                                        }
                                        {
                                            token.settings.send_email ? 
                                            <Col xs="4">
                                                <figure className="text-center">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/mail.png"} alt="Mail" width="30" />
                                                    <figcaption>MAIL</figcaption>
                                                </figure>
                                            </Col> : ""
                                        }
                                        {
                                            token.settings.token_print ?
                                            <Col xs="4">
                                                <figure className="text-center" onClick={() => { this.printToken() }}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/print.png"} alt="Print" width="30" />
                                                    <figcaption>PRINT</figcaption>
                                                </figure>
                                            </Col> : ""
                                        }
                                    </Row>
                                </Col>
                            </Row> 
                        </div>
                    </div> */}
                </>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}
 export default TokenComponent;