/**
 *  @description
 *  Footer Component
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import { APP_SUB_FOLDER } from '../../../config/app-constants';
class FooterComponent extends Component {
    render() {
        return(
            <footer id="footer">
                <Container>
                    <Row>
                        <Col xs="12" className="text-center">
                            <p>Powered By</p>
                            <figure>
                                <img  src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/logo-myhealthcare.svg"} alt="myhealthcare" />    
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}
export default FooterComponent;