/**
 *  @description
 *  Private route
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React from 'react';
import { Route, Redirect } from 'react-router';
import { APP_SUB_FOLDER } from '../config/app-constants';

const PrivateRoute = ({component: Component, isPaired, ...rest}) => (
    <Route 
        {...rest}
        render={props => (
            isPaired
            ? <Component {...props} />
            : <Redirect to={`${APP_SUB_FOLDER}/`} />
        )}
    />
);

export default PrivateRoute;
