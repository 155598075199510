/**
 *  @description
 *  Mobile number component.
 *  @author
 *  Mahesh Hegde<mahesh@innocirc.com>
 *  @version
 *  1.0.0 (12-Feb-2019)
 */

import React, { Component } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {
    getTokenForService,
    getTokenForAppointment
} from '../../actions/token-actions';

import {APP_SUB_FOLDER, TOKEN_DIALOG_TIMEOUT} from '../../../config/app-constants'

export default class PatientsListModal extends Component {

    mainBox;

    constructor(props) {
        super(props);
        this.state = {
            mobile_number: '',
            mainBoxHeight: 0,
            errors: '',
            loadingState:false
        };
        this.getToken = this.getToken.bind(this);
        this.formatTime = this.formatTime.bind(this);
        this.getWalkinToken = this.getWalkinToken.bind(this);        
    }

    componentDidMount() {
        this.setState({
            mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let height = this.mainBox ? this.mainBox.clientHeight : 0;
        if(prevState.mainBoxHeight !== height) {
            this.setState({
                mainBoxHeight: this.mainBox ? this.mainBox.clientHeight : 0
            });
        }
    }

    getWalkinToken(e){
        this.props.getWalkinToken(e, {'mobile_number': this.props.mobileNumber}, 6, 'true')
    }

    /** Mobile number form submit handler. responsible for validation. */
    getToken(e, patient_id, token_for) {
        e.preventDefault();
        this.props.getTokenForSeniorCitize(this.props.mobileNumber, this.props.service.id, patient_id, 'true', token_for)
            .then((data) => {
                if (data.data) {                    
                    this.setState({
                        loadingState:true
                    });
                    this.props.togglePatientsListModal();
                    this.props.toggleTokenModal();
                    setTimeout(() => {
                        this.props.closeTokenModal();
                    }, TOKEN_DIALOG_TIMEOUT);
                }
        });
    }


    formatTime(time) {
        if (!time) {
            return '';
        }
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1, 4);  // Remove full string match value
            time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }


    render() {
        
        return (
            <>
                <div className='modal-header'>
                        <h5>Mobile Number {this.props.mobileNumber}</h5>
                        <button className='modalClose' onClick={this.props.toggle}></button>
                </div>
                    <Form className="main-box patientTokenList" autocomplete="off" ref={ (mainBox) => this.mainBox = mainBox}>
                    
                    {
                    (this.props.PatientsDataList && this.props.PatientsDataList.has_appointment == true) ?
                        <>
                            <h6>Your Today's Appointments</h6>                           
                            {
                            this.props.PatientsDataList.patients.map((obj)=>{
                                return (
                                        <div className="card appoinment_card" key={obj.id}>
                                            <div className="card-body">
                                                <Row>
                                                    <Col xs="12" md="5">
                                                        <div>
                                                            <strong>{obj.name}</strong>
                                                        </div>
                                                        <div>
                                                            <span>Booking Code</span> <strong className="time">{obj.booking_code}</strong>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12" md="4" className="mt-3 mt-md-0">
                                                        <div>
                                                            {/* <span>Doctor</span>  */}
                                                            <strong>{obj.doctor}</strong>
                                                        </div>
                                                        <div>
                                                            <span>Time</span> <strong className="time">{this.formatTime(obj.booking_time)}</strong>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12" md="3" className="align-self-center text-center text-md-right mt-3 mt-md-0 get_token_btn">
                                                        
                                                        {this.state.loadingState == false? <button type="submit" class="px-3 btn btn-primary primary" onClick={(e) => this.getToken(e, obj.id, 'has_appointment')}>Get Token</button>: <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/loading.gif"} alt=""  />}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                );
                            })

                            }      

                            {/* <Row className="mb-3">
                                <Col xs="12"><div className='orwrap'>OR</div></Col>
                                <Col xs="12" className="text-center get_token_btn">
                                    <strong style={{color:'#374380'}}>Generate additional Walk-In token </strong>
                                    <button type="submit" class="px-3 btn btn-primary primary ml-3" onClick={(e) => this.getWalkinToken(e)} >Get Token</button>
                                </Col>
                            </Row> */}
                        </>
                        :
                        <>
                                <h6>You have no appointment today. Please select visitor and proceed</h6>
                                {
                                    this.props.PatientsDataList.patients.map((obj)=>{
                                        return (
                                                <div className="card appoinment_card" key={obj.id}>
                                                    <div className="card-body">
                                                        <Row  className="align-items-center">
                                                            <Col xs="12" sm="9" className="citizen_info"> {obj.name}, {obj.age} {obj.age > 1? "Yrs":"Yr"}, {(obj.gender).slice(0, 1)}
                                                            </Col>
                                                            
                                                            <Col xs="12" sm="3" className="align-self-center text-center text-sm-right mt-3 mt-sm-0 get_token_btn">
                                                            {this.state.loadingState == false? <button type="submit" class="px-3 btn btn-primary primary" onClick={(e) => this.getToken(e, obj.id, 'has_patients')}>Get Token</button>: <img src={process.env.PUBLIC_URL + APP_SUB_FOLDER + "/assets/images/loading.gif"} alt=""  />}

                                                                
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                        );
                                    })

                                    } 

                            {/* <Row className="mb-3">
                                <Col xs="12"><div className='orwrap'>OR</div></Col>
                                <Col xs="12" className="text-center get_token_btn">
                                    <strong style={{color:'#374380'}}>Generate additional Walk-In token </strong>
                                    <button type="submit" class="px-3 btn btn-primary primary ml-3" onClick={(e) => this.getWalkinToken(e)}>Get Token</button>
                                </Col>
                            </Row> */}
                        </>
                    }
                   
                    </Form>
            </>
        )
    }
 }

 

 const mapStateToProps = state => ({
    pair: state.pair,
    services: state.services,
    //websocket: state.websocket,
    token: state.token
});

 const mapStateToDispatch = dispatch => ({
    getTokenForSeniorCitize: (mobile_number, service_id, patient_id, has_senior_citizen, token_for) => dispatch(getTokenForService(mobile_number, service_id, patient_id, has_senior_citizen, token_for)),
});

PatientsListModal = connect(mapStateToProps, mapStateToDispatch)(PatientsListModal);