/**
 *  @description
 *  Layout Component
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
import MainContentComponent from './main-content-component';

class LayoutComponent extends Component {
    render() {
        return (
            <>
                <MainContentComponent>
                    {this.props.children}
                </MainContentComponent>
            </>
        )
    }
}

export default LayoutComponent;