/**
 *  @description
 *  Store configuration for the Fortis MyHealthcare Kiosk App.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import {
    createBrowserHistory
} from 'history';
import storage from 'redux-persist/lib/storage';
import {
    persistReducer,
    persistStore
} from 'redux-persist';
import {
    rootReducer,
    initialState
} from '../reducers/root-reducer';
import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import logger from 'redux-logger';
import {
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import thunk from 'redux-thunk';
// import {
//     websocketInit
// } from '../actions/websocket-actions';

const persistConfig = {
    key: 'myhealthcare_kiosk',
    storage,
    whitelist: [
        'pair',
        //'services',
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const history = createBrowserHistory();

export const store = createStore(
    connectRouter(history)(persistedReducer),
    initialState,
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk,
            logger,
        )
    )

);

//websocketInit(store);

export const persistor = persistStore(store);