/**
 *  @description
 *  All action types related to Services.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

export const START_REQUEST_SERVICES = 'myhealthcare:start_request_services';
export const RECEIVE_SERVICES = 'myhealthcare:receive_services';
export const ERROR_RECEIVING_SERVICES = 'myhealthcare:error_receiving_services';