/**
 *  @description
 *  Pairing reducer.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import {
    initialState
} from "./root-reducer";
import * as actionTypes from '../types/pairing-action-types';

export const PairingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_REQUEST_PAIRING_CODE:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.RECEIVE_PAIRING_CODE:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            }
        case actionTypes.ERROR_RECEIVING_PAIRING_CODE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            }
        default:
            return state;
    }
};