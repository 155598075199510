/**
 *  @description
 *  Pairing component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import PairingCodeBoxComponent from './pairing-code-box-component';
import HeaderComponent from '../layout/header-component';

class PairingComponent extends Component {
    render() {
        var code = "LOADING";
        if(this.props.pair && this.props.pair.data) {
            code = this.props.pair.data.data.otp;
        }
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <div>
                            <HeaderComponent />
                        </div>
                        {/* <div className="my-5">
                            <img src={process.env.PUBLIC_URL + '/assets/images/pairing-screen-bg.png'} alt="pairing-bg" className="img-fluid" />
                        </div> */}
                        <PairingCodeBoxComponent code={code} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default PairingComponent;