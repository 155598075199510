/**
 *  @description
 *  Main content component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
// import CalenderComponent from '../calender/mycalender';
import FooterComponent from '../layout/footer-component';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

class MainContentComponent extends Component {
    render() {
        return (
            <main id="main-content">
                <Container fluid={true}>
                            {this.props.children}
                </Container>
                <FooterComponent></FooterComponent>
            </main>
        )
    }
};

export default MainContentComponent;
