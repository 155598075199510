/**
 *  @description
 *  Pairing code box component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, { Component } from 'react';
import LoadingComponent from '../loading-component';

class PairingCodeBoxComponent extends Component {
    render() {
        return (
            <div className="row">
                <div className='col-12'>
                    <div className="pairing-code-box">
                        <div className="pairing-code display-3 font-weight-normal mb-2">{this.props.code}</div>
                        <h1 className="pairing-code-helptext font-weight-light">Login into MyHealthcare dashboard. From “Devices” option add this code to pair the devices.</h1>                        
                        <LoadingComponent />
                    </div>
                </div>
            </div>

        )
    }
}
export default PairingCodeBoxComponent;