/**
 *  @description
 *  Services component.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import React, {
    Component
} from 'react';
import ServiceComponent  from './service-component';
import HeaderComponent from '../layout/header-component';
import {
    Row,
    Col
} from 'reactstrap';

class ServicesComponent extends Component {

    render() {
        var response = this.props.services.data;
        var services = [], 
            serviceList = <Col><div className="text-center mt-5">No Services configured yet.</div></Col>;
        if(response) {
            services = response.data.services;
            serviceList = services.map((service) => (
                <Col xs="12" sm="10" md="4" className="mt-5 mb-4 mb-lg-0" key={service.id}>
                    <ServiceComponent 
                        service={service} 
                        onClicked={this.props.onServiceClicked} 
                        />
                </Col>
            ));
        };
        return (
            <>
                <HeaderComponent branch={this.props.branch}/>
                <Row className="justify-content-center get_token_items">
                    {serviceList}
                </Row>
            </>
        )
    }
}

export default ServicesComponent;