/**
 *  @description
 *  Actions related to services.
 *  @author
 *  Mahesh Hegde<mahesh&#64;innocirc.com>
 *  @version
 *  1.0.0 (18-Dec-2018)
 */

import * as actionTypes from '../types/service-action-types';
import {
    API_URI
} from '../../config/app-constants';
import api from '../../config/api';

export const startRequest = () => ({
    type: actionTypes.START_REQUEST_SERVICES,
});

export const receiveData = (data) => ({
    type: actionTypes.RECEIVE_SERVICES,
    payload: data,
});

export const receiveError = (error) => ({
    type: actionTypes.ERROR_RECEIVING_SERVICES,
    payload: error,
});

export function getServices(device_details) {
    return (dispatch) => {
        dispatch(startRequest());
        return api.post(API_URI + '/kiosk-services', {
                device_model_number: device_details.device_model_number ? device_details.device_model_number : "Tablet",
                battery_percentage: device_details.battery_percentage ? device_details.battery_percentage : 0,
            })
            .then(
                response => dispatch(receiveData(response)),
                error => {
                    console.log(error);
                    dispatch(receiveError(error));
                }
            );
    }
}